import { IntervalSettings } from "../const/intervals";
import store from "../redux/store";
import { addInterval, removeInterval } from "../redux/reducers/intervals/slice";
import { selectInterval } from "../redux/reducers/intervals/selectors";

export const createInterval = (
	settings: IntervalSettings,
	callback: () => void,
	customOptions?: { interval?: number },
) => {
	destroyInterval(settings);
	const intervalId = setInterval(callback, customOptions?.interval || settings.interval);
	store.dispatch(
		addInterval({
			settings: { ...settings, ...customOptions },
			id: intervalId,
		}),
	);
};
export const destroyInterval = (settings: IntervalSettings) => {
	const interval = selectInterval(store.getState(), settings.key);
	if (!interval) return;
	clearInterval(interval.id);
	store.dispatch(removeInterval(settings.key));
};
