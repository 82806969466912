import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { TimeoutSettings } from "../../../const/timeouts";

export type StoredTimeout = {
	settings: TimeoutSettings;
	id: number;
};
const timeoutsAdapter = createEntityAdapter<StoredTimeout>({
	selectId: (timeout) => timeout.settings.key,
});

const timeoutsSlice = createSlice({
	name: "timeouts",
	initialState: timeoutsAdapter.getInitialState(),
	reducers: {
		addTimeout: (draft, action) => timeoutsAdapter.addOne(draft, action),
		removeTimeout: (draft, action) => timeoutsAdapter.removeOne(draft, action),
	},
});

export const { addTimeout, removeTimeout } = timeoutsSlice.actions;
export default timeoutsSlice.reducer;
