import { createInterval, destroyInterval } from "../../misc/interval";
import { placeholderCursor, placeholderSearch } from "../../const/intervals";
import React from "react";
import { predefinedPlaceholders } from "../../const/search-bar";
import { createTimeout, destroyTimeout } from "../../misc/timeout";
import { placeholderTimeout, placeholderTimeoutTotal } from "../../const/timeouts";

const getRandomPredefinedPlaceholder = () => predefinedPlaceholders[Math.floor(Math.random() * 6)];

export const startPlaceholderAnimation = (setPlaceholder: React.Dispatch<React.SetStateAction<string>>) => {
	startPlaceholderCursorAnimation(setPlaceholder);

	const placeholder = getRandomPredefinedPlaceholder();
	const totalTime = placeholderSearch.interval * placeholder.length;

	createInterval(placeholderSearch, () => {
		setPlaceholder((p) => {
			let newString = placeholder.substring(0, p.length + 1);
			if (p.endsWith("|")) newString += "|";
			return newString;
		});
	});
	createTimeout(
		placeholderTimeoutTotal,
		() => {
			destroyInterval(placeholderSearch);
			createTimeout(placeholderTimeout, () => {
				setPlaceholder("");
				startPlaceholderAnimation(setPlaceholder);
			});
		},
		{ after: totalTime },
	);
};
export const endPlaceholderAnimation = () => {
	endPlaceholderCursorAnimation();
	destroyInterval(placeholderSearch);
	destroyTimeout(placeholderTimeoutTotal);
	destroyTimeout(placeholderTimeout);
};

export const startPlaceholderCursorAnimation = (setPlaceholder: React.Dispatch<React.SetStateAction<string>>) => {
	createInterval(placeholderCursor, () => {
		setPlaceholder((p) => {
			if (p.endsWith("|")) return p.substring(0, p.length - 1);
			else return p + "|";
		});
	});
};
export const endPlaceholderCursorAnimation = () => {
	destroyInterval(placeholderCursor);
};
