import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { IntervalSettings } from "../../../const/intervals";

export type StoredInterval = {
	settings: IntervalSettings;
	id: number;
};
const intervalsAdapter = createEntityAdapter<StoredInterval>({
	selectId: (interval) => interval.settings.key,
});

const intervalsSlice = createSlice({
	name: "intervals",
	initialState: intervalsAdapter.getInitialState(),
	reducers: {
		addInterval: (draft, action) => intervalsAdapter.addOne(draft, action),
		removeInterval: (draft, action) => intervalsAdapter.removeOne(draft, action),
	},
});

export const { addInterval, removeInterval } = intervalsSlice.actions;
export default intervalsSlice.reducer;
