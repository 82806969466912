import React, { useEffect, useState } from "react";
import "./search-bar.scss";
import { useAppDispatch } from "../../redux/store";
import { endSearch, startSearch } from "../../redux/reducers/page/slice";
import { useSelector } from "react-redux";
import { selectSearchingState } from "../../redux/reducers/page/selectors";
import { endPlaceholderAnimation, startPlaceholderAnimation } from "./search-bar";
import useDebouncy from "use-debouncy/lib/fn";

const SearchBar = () => {
	const dispatch = useAppDispatch();
	const searching = useSelector(selectSearchingState);

	const [placeholder, setPlaceholder] = useState("");
	const [content, setContent] = useState("");
	const handleContentChange = useDebouncy((change) => setContent(change.target.value));

	useEffect(() => {
		if (content || searching) setPlaceholder("");
		if (!searching && !content) startPlaceholderAnimation(setPlaceholder);
		else endPlaceholderAnimation();
	}, [content, searching]);

	return (
		<div className="app-search">
			<div className="app-search-header">Fictionsearch.net</div>
			<input
				className="app-search-bar"
				onFocus={() => dispatch(startSearch())}
				onBlur={() => dispatch(endSearch())}
				placeholder={placeholder}
				onChange={handleContentChange}
			/>
		</div>
	);
};

export default SearchBar;
