import { combineReducers } from "@reduxjs/toolkit";
import page from "./page/slice";
import intervals from "./intervals/slice";
import timeouts from "./timeouts/slice";

const rootReducer = combineReducers({
	page,
	intervals,
	timeouts,
});

export default rootReducer;
