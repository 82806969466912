import { availableColors } from "../../../const/shelf";

export type RBook = {
	height: number;
	width: number;
	color: string;
	vertical: boolean;
	inverted: boolean;
	ornaments: BOrnament[];
};
export type BOrnament = {
	height: number;
	count: number;
	color: string;
	padding: number;
	margin: number;
};

const getInitValue = () => 10 + Math.floor(Math.random() * 91);
const getRandomWidth = (vertical: boolean) => (vertical ? 10 : 40) + Math.floor(Math.random() * (vertical ? 15 : 40));
const getRandomColor = () => availableColors[Math.floor(Math.random() * 15)];

export const initBook = (): RBook => {
	const book = {} as RBook;
	const ornaments = [] as BOrnament[];

	const initVal = getInitValue();
	const vertical = initVal >= 25;

	for (let i = 0; i < Math.random() * 2; i++) ornaments.push(initOrnament());

	book.width = getRandomWidth(vertical);
	book.height = initVal;
	book.color = getRandomColor();
	book.vertical = vertical;
	book.ornaments = ornaments;
	book.inverted = Math.random() <= 0.2;

	return book;
};

export const initOrnament = (): BOrnament => {
	const ornament = {} as BOrnament;
	const isBlack = Math.random() < 0.5;
	const opacity = (10 + Math.random() * 71) / 100;

	ornament.color = `rgba(${isBlack ? "0,0,0" : "1,1,1"},${opacity})`;
	ornament.count = Math.floor(Math.random() * 2) + 1;
	ornament.height = 2 + Math.floor(Math.random() * 6);

	ornament.margin = 1 + Math.floor(Math.random() * 2);
	ornament.padding = 1 + Math.floor(Math.random() * 3);

	return ornament;
};
