import React, { useEffect, useRef } from "react";
import "./search-layer.scss";
import { useSelector } from "react-redux";
import { selectSearchingState } from "../../redux/reducers/page/selectors";
import { endFlashlight, startFlashlight } from "./search-layer";

/**
 * The SearchLayer component adds a "shadow"-Layer to obscure the pages background.
 * While the user is searching this layer gets broken up by a flashlight.
 *
 * @returns {JSX.Element} SearchLayer component.
 */
function SearchLayer() {
	const searching = useSelector(selectSearchingState);

	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (searching) startFlashlight(ref);
		else endFlashlight(ref);
		return () => endFlashlight(ref);
	}, [searching]);

	return <div ref={ref} className="search-layer" />;
}

export default SearchLayer;
