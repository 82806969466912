import store from "../redux/store";
import { TimeoutSettings } from "../const/timeouts";
import { addTimeout, removeTimeout } from "../redux/reducers/timeouts/slice";
import { selectTimeout } from "../redux/reducers/timeouts/selectors";

export const createTimeout = (settings: TimeoutSettings, callback: () => void, customOptions?: { after?: number }) => {
	destroyTimeout(settings);
	const timeoutId = setTimeout(callback, customOptions?.after || settings.after);
	store.dispatch(
		addTimeout({
			settings: { ...settings, ...customOptions },
			id: timeoutId,
		}),
	);
};
export const destroyTimeout = (settings: TimeoutSettings) => {
	const timeout = selectTimeout(store.getState(), settings.key);
	if (!timeout) return;
	clearTimeout(timeout.id);
	store.dispatch(removeTimeout(settings.key));
};
