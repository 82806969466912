import React, { useState } from "react";
import "./shelf.scss";
import { initShelf } from "./shelf";
import BookStack from "../BookStack";

function Shelf() {
	const [compartments] = useState(initShelf());

	return (
		<div className="bookshelf">
			{compartments.map((c, i) => (
				<div className="compartment" key={`bc-${i}`}>
					<div className="book-container">
						{c.books.map((b, j) => (
							<BookStack key={`bs-${j}`} stack={b} />
						))}
					</div>
					<div className="plate" />
				</div>
			))}
		</div>
	);
}

export default Shelf;
