import { initBook, RBook } from "../books/random-book";

export type BStack = {
	horizontal: RBook[];
	vertical: RBook[];
};
type Compartment = {
	books: BStack[];
};

export const initShelf = () => {
	const compartmentCount = window.innerHeight / 130;
	const compartments = [] as Compartment[];

	for (let cc = 0; cc < compartmentCount; cc++) {
		compartments.push({
			books: fillUpShelf(110),
		});
	}

	return compartments;
};

const fillUpShelf = (compartmentHeight: number): BStack[] => {
	const books = [] as BStack[];
	let limiter = window.innerWidth;

	while (limiter > 0) {
		const stack = { horizontal: [], vertical: [] } as BStack;
		let book = initBook();
		limiter -= book.width;

		while (book.vertical) {
			stack.vertical.push(book);
			book = initBook();
			limiter -= book.width;
		}

		books.push(stack);
		books.push(fillUpBookStack(book, compartmentHeight));
	}

	return books;
};
const fillUpBookStack = (firstBook: RBook, heightLimit: number) => {
	const stack = { horizontal: [], vertical: [] } as BStack;
	let subWL = firstBook.width;
	let subHL = heightLimit - firstBook.height;

	if (!firstBook.vertical) {
		stack.horizontal.push(firstBook);

		let highestVerticalBook = 0;

		while (subWL > 0 && subHL > 0) {
			const book = initBook();

			if (
				book.height >= subHL ||
				(book.vertical && book.width >= subWL) ||
				(!book.vertical && book.width >= firstBook.width)
			)
				return stack;

			if (book.vertical) {
				stack.vertical.push(book);

				if (highestVerticalBook < book.height) {
					subHL -= book.height - highestVerticalBook;
					highestVerticalBook = book.height;
				}

				subWL -= book.width;
			} else {
				stack.horizontal.push(book);
			}
		}
	}

	return stack;
};
