import React from "react";
import "./random-book.scss";
import { RBook } from "./random-book";
import BookOrnament from "./BookOrnament";

function RandomBook({ book }: { book: RBook }) {
	return (
		<div
			className="book"
			style={{
				flexDirection: book.vertical ? "column" : "initial",
				justifyContent: book.inverted ? "flex-end" : "flex-start",
				width: book.width,
				height: book.height,
				background: book.color,
			}}
		>
			<BookOrnament book={book} />
		</div>
	);
}

export default RandomBook;
