export type IntervalSettings = {
	key: string;
	interval: number;
};

const initInterval = (key: string, interval: number) => ({
	key,
	interval,
});

export const flashlightInterval: IntervalSettings = initInterval("flashlight", 2000);
export const placeholderCursor: IntervalSettings = initInterval("placeholderCursor", 700);
export const placeholderSearch: IntervalSettings = initInterval("placeholderSearch", 75);
