import { RefObject } from "react";
import { createInterval, destroyInterval } from "../../misc/interval";
import { flashlightInterval } from "../../const/intervals";

const getHiddenBackground = () => "-100% -100%";
const getFlashlightBackground = (x: number, y: number) => `${x}px ${y}px`;

const placeFlashlight = (ref: RefObject<HTMLDivElement>) => {
	if (!ref?.current) return;

	const x = Math.floor(Math.random() * (window.innerWidth - 66)) - 66;
	const y = Math.floor(Math.random() * (window.innerHeight - 66)) - 66;

	ref.current.style.backgroundPosition = getFlashlightBackground(x, y);
};

export const startFlashlight = (ref: RefObject<HTMLDivElement>) => {
	createInterval(flashlightInterval, () => placeFlashlight(ref));
};
export const endFlashlight = (ref: RefObject<HTMLDivElement>) => {
	destroyInterval(flashlightInterval);
	if (ref.current) ref.current.style.backgroundPosition = getHiddenBackground();
};
