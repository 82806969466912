import { createSlice } from "@reduxjs/toolkit";

const pageSlice = createSlice({
	name: "page",
	initialState: {
		searching: false,
	},
	reducers: {
		startSearch: (draft) => {
			draft.searching = true;
		},
		endSearch: (draft) => {
			draft.searching = false;
		},
	},
});

export const { startSearch, endSearch } = pageSlice.actions;
export default pageSlice.reducer;
