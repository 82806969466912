import React from "react";
import "./book-ornament.scss";
import { RBook } from "./random-book";

function BookOrnament({ book }: { book: RBook }) {
	return (
		<>
			{(() => {
				const ornaments = [];

				for (let n = 0; n < book.ornaments.length; n++) {
					const ornament = book.ornaments[n];

					for (let i = 0; i < ornament.count; i++) {
						const width = book.vertical ? book.width : ornament.height;
						const height = book.vertical ? ornament.height : book.height;

						ornaments.push(
							<div
								className="book-ornament"
								key={`bo-${n}_${i}`}
								style={{
									width,
									height,
									background: ornament.color,
									marginTop: !book.vertical ? 0 : i === 0 ? ornament.padding : ornament.margin,
									marginLeft:
										!book.vertical && !book.inverted
											? i === 0
												? ornament.padding
												: ornament.margin
											: 0,
									marginRight:
										!book.vertical && book.inverted
											? i === 0
												? ornament.padding
												: ornament.margin
											: 0,
								}}
							/>,
						);
					}
				}

				return ornaments;
			})()}
		</>
	);
}

export default BookOrnament;
