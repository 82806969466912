import React, { useState } from "react";
import { BStack } from "./shelf/shelf";
import RandomBook from "./books/RandomBook";
import "./book-stack.scss";

function BookStack({ stack }: { stack: BStack }) {
	const [alignment] = useState(() => {
		const rv = Math.random();
		if (rv > 0.5) return "flex-end";
		if (rv > 0.25) return "center";
		return "flex-start";
	});

	return (
		<div className="book-stack">
			<div style={{ justifyContent: alignment }} className="book-stack--vertical">
				{stack.vertical.map((b, i) => (
					<RandomBook key={`book-v-${i}`} book={b} />
				))}
			</div>
			<div className="book-stack--horizontal">
				{stack.horizontal.reverse().map((b, i) => (
					<RandomBook key={`book-h-${i}`} book={b} />
				))}
			</div>
		</div>
	);
}

export default BookStack;
