export type TimeoutSettings = {
	key: string;
	after: number;
};

const initTimeout = (key: string, after: number) => ({
	key,
	after,
});

export const placeholderTimeoutTotal: TimeoutSettings = initTimeout("placeholderSearchTotal", 2000);
export const placeholderTimeout: TimeoutSettings = initTimeout("placeholderSearch", 5500);
