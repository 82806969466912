import React from "react";
import "./App.scss";
import Shelf from "./bookshelf/shelf/Shelf";
import SearchLayer from "./searchlayer/SearchLayer";
import SearchBar from "./searchbar/SearchBar";

/**
 * Wrapper containing all components for the site.
 *
 * @returns {JSX.Element} The app component.
 */
function App() {
	return (
		<div className="app">
			<SearchLayer />
			<Shelf />
			<SearchBar />
		</div>
	);
}

export default App;
