export const availableColors = [
	"#023B47",
	"#295E52",
	"#F2E085",
	"#FCAB55",
	"#EE7F38",
	"#240C26",
	"#3B152F",
	"#592635",
	"#873034",
	"#E0322F",
	"#354242",
	"#ACEBAE",
	"#FFFF9D",
	"#C9DE55",
	"#7D9100",
];
